import React, { useEffect } from "react";

import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import plausible from "../components/plausible";

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof plausible === "function") {
      plausible(`404`, { props: { path: document.location.pathname } });
    }
  }, []);

  return (
    <div className="bg-gray-50">
      <SEO title="7works: Page Not found" />
      <Nav />
      <h1>NOT FOUND</h1>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
