import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from "../components/Container";

const PostCard = ({ post }) => {
  const {
    fields: { slug },
    frontmatter: { title, date },
  } = post;

  const excerpt = post.excerpt;
  return (
    <Link
      to={slug}
      key={title}
      className="transition-shadow bg-white p-5 rounded-lg border hover:shadow-lg"
    >
      <p className="text-sm text-gray-500">
        <time>{date}</time>
      </p>
      <div className="mt-2 block">
        <p className="text-xl font-semibold text-gray-900">{title}</p>
        <p className="mt-3 line-clamp-2 text-base text-gray-500">{excerpt}</p>
      </div>
      <div className="mt-3">
        <div className="text-base font-semibold text-indigo-600 hover:text-indigo-500">
          Read more
        </div>
      </div>
    </Link>
  );
};

const News = ({ data }) => {
  const posts = data.allMdx.nodes
    .filter(
      (n) =>
        n.parent.sourceInstanceName === "news" && n.frontmatter.draft !== true
    )
    .reverse();
  return (
    <div className="bg-gray-50">
      <SEO title="News" />
      <Nav />
      <Container>
        <div className="text-center mx-auto py-8 border-b">
          <div className="font-serif text-4xl font-bold">Announcements</div>
          <div className="mt-6 max-w-24">
            <div className="text-gray-700 text-lg italic">
              updates to the 7works platform and tips to run your service
              program
            </div>
          </div>
        </div>
        <div className="pb-20">
          <div className="mt-6 gap-4 pt-10 grid lg:grid-cols-2 lg:gap-y-8">
            {posts.map((post) => (
              <PostCard key={post.id} post={post} />
            ))}
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default News;

// Get all markdown news posts sorted by date
export const query = graphql`
  {
    allMdx(sort: { fields: [fileAbsolutePath], order: ASC }, limit: 1000) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          draft
          dateRaw: date(formatString: "YYYY-MM-DD")
          date(formatString: "MMMM DD, YYYY")
          description
        }
        parent {
          ... on File {
            id
            name
            sourceInstanceName
          }
        }
      }
    }
  }
`;
