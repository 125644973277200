import React from "react";
import { fetch } from "whatwg-fetch";
import { FORM_ERROR, ValidationErrors } from "final-form";
import { Form, useForm, useField, useFormState, Field } from "react-final-form";
import { CheckCircleIcon } from "@heroicons/react/solid";

import Seo from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from "../components/Container";
import TextField from "../components/form/TextField";
import Submit from "../components/form/Submit";
import SubmitError from "../components/form/SubmitError";
import When from "../components/form/When";

async function sendToApi(method: string, path: string, data: any) {
  const body = data ? JSON.stringify(data) : undefined;
  let res;
  let baseUrl = "https://app.7works.org";
  if (window?.location?.host === "localhost:8000") {
    baseUrl = "";
  }

  try {
    res = await fetch(`${baseUrl}${path}`, {
      method,
      credentials: "same-origin",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } catch (err: any) {
    return { body: null, error: err?.message, status: 0 };
  }

  if (!res.ok) {
    const respText = await res.text();
    let error: string | null = null;
    let jResp;
    try {
      jResp = JSON.parse(respText);
    } catch (err) {
      // do nothing
    }

    if (jResp && jResp.message) {
      error = jResp.message;
    } else if (respText) {
      error = respText;
    } else {
      error = `Error (${res.status}): ${method} to ${path}`;
    }

    return { body: null, error, status: res.status };
  }

  try {
    const respBody = await res.json();
    return { body: respBody, error: null, status: res.status };
  } catch (err: any) {
    return { body: null, error: err.message, status: res.status };
  }
}

const Warning = ({ children }) => (
  <div className="bg-orange-100 pl-6 p-2 col-span-2 border-l-4 border-orange-900 text-orange-900">
    {children}
  </div>
);

const FormFooter = () => {
  const s = useFormState({
    subscription: { submitError: true, submitSucceeded: true },
  });

  return (
    <>
      {s.submitSucceeded && (
        <div className="col-span-2">
          <ThankYou />
        </div>
      )}
      <When field="role" is="student">
        <Warning>
          In order to participate in 7works as a student, your school
          administrator will need to invite you.
          <br /> <br /> Please reach out to your school or{" "}
          <a className="underline" href="https://app.7works.org/login">
            sign in here.
          </a>
        </Warning>
      </When>
      <div>
        <SubmitError />
      </div>
      {!s.submitSucceeded && (
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <Submit>Continue</Submit>
        </div>
      )}
    </>
  );
};

const ThankYou = () => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden={true}
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Verify Email to Continue
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <p>
              Thank you for registering with 7works, please check your email to
              continue!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const roles = [
  { label: "Teacher / Administrator", value: "admin" },
  { label: "Student / Volunteer", value: "student" },
];

const RoleField = () => {
  return (
    <fieldset>
      <label className="text-base font-medium text-gray-900">Your role</label>
      <p className="text-sm leading-5 text-gray-500 mb-4">
        What is your role in this school / organization?
      </p>
      <div className="space-y-4">
        {roles.map((r) => (
          <div key={r.value} className="flex flex-row items-center">
            <Field
              name="role"
              type="radio"
              value={r.value}
              component="input"
              id={`signup_form_${r.value}`}
              className="h-4 w-4 border-gray-300 text-teal-600 focus:ring-teal-500"
            />
            <label
              htmlFor={`signup_form_${r.value}`}
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              {r.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

const formIsValid = (values: any): ValidationErrors => {
  const emailValid = values?.email?.indexOf("@") > 0;
  const roleValid = values?.role === "admin";

  let errors: Record<string, any> = {};
  if (!roleValid) {
    errors.role = "error";
  }
  if (!emailValid) {
    errors.email = "error";
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  return {};
};

const SignupForm = () => {
  const onSubmit = async (values: Record<string, any>) => {
    const { error } = await sendToApiSlow("POST", "/api/signup", {
      email: values.email,
    });

    if (error) {
      return { [FORM_ERROR]: error };
    }

    return null;
  };

  return (
    <Form onSubmit={onSubmit} validate={formIsValid}>
      {(props) => (
        <form
          onSubmit={props.handleSubmit}
          className="grid grid-cols-1 gap-y-6 sm:gap-x-8"
        >
          <div className="sm:col-span-2">
            <RoleField />
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-900 mt-4"
            >
              Your Email
            </label>
            <div className="mt-1">
              <TextField name="email" autoComplete="email" />
            </div>
          </div>
          <FormFooter />
        </form>
      )}
    </Form>
  );
};

const Signup = () => {
  return (
    <div className="bg-white my-8">
      <div className="mt-8 flex flex-col md:flex-row bg-gray-100">
        <div className="flex-1 p-10">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl font-serif">
            Sign up
          </h2>
          <p className="mt-3 text-lg leading-6 text-gray-500">
            Register your school or service organization with 7works to start
            digitally tracking your service hours.
          </p>
        </div>
        <div className="border border-gray-200 p-10 flex-1 bg-white">
          <SignupForm />
        </div>
      </div>
    </div>
  );
};

async function sendToApiSlow(method: string, path: string, data: any) {
  const p = new Promise((resolve) => setTimeout(resolve, 1000));
  return sendToApi(method, path, data).then(
    async (res) => {
      await p;
      return Promise.resolve(res);
    },
    async (err) => {
      await p;
      return Promise.reject(err);
    }
  );
}

const Contact = () => {
  return (
    <div className="bg-white min-h-full">
      <Seo title="Signup" />
      <Nav showCta={false} />
      <Container>
        <Signup />
      </Container>
      <Footer />
    </div>
  );
};

export default Contact;
