import React from "react";
import { useField } from "react-final-form";

type Props = {
  field: string;
  is: any;
  children: JSX.Element;
};

const When = ({ field, is, children }: Props) => {
  const {
    input: { value },
  } = useField(field, {
    subscription: {
      value: true,
    },
  });

  if (value === is) {
    return children;
  }

  return null;
};

export default When;
