import React from "react";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";

interface Page {
  name: string;
  to?: string;
}

interface Props {
  pages: Page[];
}

const Breadcrumbs = ({ pages }: Props) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {page.to && (
                <Link
                  to={page.to}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </Link>
              )}
              {!page.to && (
                <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {page.name}
                </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
