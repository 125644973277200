import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { StaticImage } from "gatsby-plugin-image";

import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from "../components/Container";
import DemoMp4 from "../demo.mp4";
import LaptopGoalsImg from "../images/laptop-goals.png";

import {
  GlobeAltIcon,
  ScaleIcon,
  AcademicCapIcon,
  LightningBoltIcon,
  ShieldCheckIcon,
  ClipboardListIcon,
  HomeIcon,
} from "@heroicons/react/outline";

import { ArrowNarrowRightIcon, PlayIcon } from "@heroicons/react/solid";

const features = [
  {
    name: "Students",
    description:
      "Students can upload hours and view their progress toward their goals - at any time of the day or night. They can request verification from their supervisor via email or text message.",
    icon: GlobeAltIcon,
  },
  {
    name: "Administators...",
    description:
      "can configure the service categories and requirements for their students. And approving service hours is a simple click.",
    icon: ScaleIcon,
  },
  {
    name: "Parents",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: LightningBoltIcon,
  },
];

const FeatureHeading = ({ children }) => {
  return (
    <h2 className="text-4xl font-extrabold text-gray-900 font-serif">
      {children}
    </h2>
  );
};

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

interface VideoDialogProps {
  open: boolean;
  onClose: () => void;
}

const VideoDialog = ({ onClose, open }: VideoDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
      <div className="relative flex flex-col">
        <button
          onClick={onClose}
          className="text-white fill-current self-end p-4 outline-none mr-4"
        >
          <CloseIcon />
        </button>
        <video controls autoPlay className="rounded-lg">
          <source src={DemoMp4} type="video/mp4" />
        </video>
      </div>
    </Dialog>
  );
};

const HeroText = () => {
  return (
    <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block">Run your service program</span>
          <span className="block italic text-teal-700">without paper</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          7works eliminates paper forms and Excel from your service program,
          <br className="hidden md:inline" /> leaving you with more time for
          your students and community.
        </p>
      </div>
    </div>
  );
};

const Hero = () => {
  const [open, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  return (
    <>
      <VideoDialog open={open} onClose={handleClose} />
      <HeroText />
      <div className="flex mx-4 flex-col gap-4 md:flex-row justify-center items-stretch mt-4 mb-16">
        <Link
          to="/signup"
          className="inline-flex items-center bg-teal-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-teal-900"
        >
          Get started for Free
          <ArrowNarrowRightIcon className="ml-4 h-5 w-5" />
        </Link>
        <button
          onClick={handleOpen}
          className="flex py-2 px-4 flex-row items-center border border-teal-700 text-base font-medium rounded-md text-teal-700 hover:text-teal-800"
        >
          <PlayIcon className="h-10 w-10" />
          <div className="flex flex-col items-start ml-3">
            <div className="font-bold">View Demo</div>
            <div className="text-gray-400 text-sm -mt-1 font-semibold">
              1 min
            </div>
          </div>
        </button>
      </div>
      <div className="bg-gradient-to-b from-slate-100 to-transparent overflow-hidden">
        <Container>
          <StaticImage
            placeholder="none"
            src="../images/landing.png"
            alt="Product Screenshot"
          />
        </Container>
      </div>
    </>
  );
};
const FeatureText = ({ children }) => {
  return <p className="mt-4 text-lg text-gray-500">{children}</p>;
};

const EasyToUse = () => {
  return (
    <div className="min-h-[800px] py-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-700">
              <LightningBoltIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="mt-6">
            <FeatureHeading>Fast! And easy to use</FeatureHeading>
            <FeatureText>
              7works was designed for speed and simplicity. No more waiting 15
              seconds to load a student record, and no user manuals to read.
            </FeatureText>
            <div className="mt-6">
              <Link
                to="/signup"
                className="inline-flex bg-slate-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-gray-800"
              >
                Get started
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0">
        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img
            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src={LaptopGoalsImg}
            alt="Admin user interface"
            height={768}
          />
        </div>
      </div>
    </div>
  );
};

const SignupLink = ({ children }) => {
  return (
    <Link
      to="/signup"
      className="inline-flex bg-slate-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-gray-800"
    >
      {children}
    </Link>
  );
};

const ServiceTranscripts = () => {
  return (
    <div className="items-center py-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-700">
              <ClipboardListIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="mt-6">
            <FeatureHeading>Service Transcripts</FeatureHeading>
            <FeatureText>
              Generate PDF service transcripts at any time. Students can use
              these service transcripts to apply for scholarships, college or a
              job.
            </FeatureText>
            <div className="mt-6">
              <SignupLink>Get started</SignupLink>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center self-stretch mt-8 lg:-order-1">
        <StaticImage
          className="border rounded-lg shadow-2xl"
          width={512}
          layout="constrained"
          src="../images/service-transcript.png"
          alt="Service Transcript"
        />
      </div>
    </div>
  );
};

const ParentsInTheLoop = () => {
  return (
    <div className="min-h-[800px] items-center py-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-700">
              <HomeIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-6">
            <FeatureHeading>Email Progress Reports</FeatureHeading>
            <FeatureText>
              Email progress reports keep parents in the loop! This provides
              another layer of accountability and avoids any suprises in case
              students slip on their goals.
            </FeatureText>
            <div className="mt-6">
              <SignupLink>Get started</SignupLink>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center self-stretch mt-8">
        <StaticImage
          className="border rounded-lg shadow-2xl"
          width={512}
          layout="fixed"
          src="../images/progress-report.png"
          alt="Progress Report"
        />
      </div>
    </div>
  );
};

const Verifications = () => {
  return (
    <div className="min-h-[800px] items-center py-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-700">
              <ShieldCheckIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="mt-6">
            <FeatureHeading>Automatic Verification</FeatureHeading>
            <FeatureText>
              Keep students accountable with optional SMS and email
              verifications. Supervisors can receive an SMS or email link to
              confirm the volunteer hours completely automatically.
            </FeatureText>
            <div className="mt-6">
              <SignupLink>Get started</SignupLink>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center self-stretch mt-8">
        <StaticImage
          className="border rounded-lg shadow-2xl"
          width={512}
          layout="fixed"
          src="../images/verification1.png"
          alt="Email Verification"
        />
      </div>
    </div>
  );
};

const SchoolCloud = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base uppercase font-semibold text-gray-600 tracking-wider">
          Trusted by a growing community of schools
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-0.5">
          <div className="col-span-1 flex items-center justify-center py-8 px-8 group">
            <a href="https://stanthony.com/high-school">
              <StaticImage
                height={100}
                placeholder="none"
                className="transition-all duration-500 grayscale opacity-50 group-hover:grayscale-0 group-hover:opacity-100"
                src="../images/school-sta.png"
                alt="St. Anthony High School"
              />
            </a>
          </div>
          <div className="col-span-1 flex flex-col items-center justify-center py-8 px-8 group">
            <a href="https://www.mcgivneygriffins.com/">
              <StaticImage
                placeholder="none"
                height={100}
                className="transition-all duration-500 grayscale opacity-50 group-hover:grayscale-0 group-hover:opacity-100"
                src="../images/school-fmchs.png"
                alt="Father McGivney Catholic High School"
              />
            </a>
          </div>
          <div className="col-span-1 flex flex-col items-center justify-center py-8 px-8 group">
            <a href="https://www.marquettecatholic.org/">
              <StaticImage
                placeholder="none"
                height={100}
                className="transition-all duration-500 grayscale opacity-50 group-hover:grayscale-0 group-hover:opacity-100"
                src="../images/school-mchs.png"
                alt="Marquette Catholic High School"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = () => {
  return (
    <section className="py-12 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div className="relative">
          <blockquote className="mt-10">
            <div className="max-w-7xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo;Having an app is mind-blowing.&rdquo;
                <br />
                &ldquo;With no direction, the students know what to do - that
                has been amazing.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0 text-center">
                  <StaticImage
                    placeholder="none"
                    height={48}
                    className="mx-auto h-10 w-10 rounded-full"
                    src="../images/avatar-amy-b.jpg"
                    alt="Amy B Avatar"
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    Amy B
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-gray-800"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    Campus Ministry Director
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

const SimpleForStudents = () => {
  return (
    <div className="py-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-700">
              <AcademicCapIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="mt-6">
            <FeatureHeading>Simple for students</FeatureHeading>
            <FeatureText>
              Students need <i>no training</i> to use 7works. The app is
              familiar and intuitive for them. It works on all phones, laptops,
              tablets and desktop computers. And with Google Login, there is not
              even a password to remember.
            </FeatureText>
            <p className="mt-4 text-lg text-gray-500">
              Why waste time doing IT support? With 7works you don't —&nbsp;
              <b>it&nbsp;just&nbsp;works!</b>
            </p>
            <div className="mt-6">
              <SignupLink>Get started</SignupLink>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center mt-8 lg:mt-0">
        <Swiper
          className="min-h-[850px]"
          pagination={true}
          navigation={true}
          modules={[Pagination, Navigation]}
        >
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center w-full h-full pb-12">
              <StaticImage
                height={800}
                src="../images/mobile-student-home.png"
                alt="Student homepage screenshot"
                objectFit="contain"
              />
              <span className="text-gray-400 italic">Home Screen</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center w-full h-full pb-12">
              <StaticImage
                height={800}
                src="../images/mobile-student-upload.png"
                alt="Student upload screenshot"
                objectFit="contain"
              />
              <span className="text-gray-400 italic">Uploading Hours</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center w-full h-full pb-12">
              <StaticImage
                height={800}
                src="../images/mobile-student-view.png"
                alt="Student view screenshot"
                objectFit="contain"
              />
              <span className="text-gray-400 italic">Viewing Hours</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center w-full h-full pb-12">
              <StaticImage
                height={800}
                src="../images/mobile-student-edit.png"
                alt="Student edit screenshot"
                objectFit="contain"
              />
              <span className="text-gray-400 italic">Editing Hours</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center w-full h-full pb-12">
              <StaticImage
                height={800}
                src="../images/mobile-student-approved.png"
                alt="Student approved screenshot"
                objectFit="contain"
              />
              <span className="text-gray-400 italic">
                Viewing Approved Hours
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

const Section = ({ children }) => {
  return (
    <div className="bg-gradient-to-b from-slate-100 to-transparent overflow-hidden">
      {children}
    </div>
  );
};

const IndexPage = () => {
  return (
    <div className="bg-gray-50">
      <SEO title="School Service Program Platform | Volunteer Tracking" />
      <div className="bg-white">
        <Nav />
      </div>
      <Section>
        <Hero />
      </Section>
      <SchoolCloud />
      <Section>
        <SimpleForStudents />
      </Section>
      <Section>
        <ParentsInTheLoop />
      </Section>
      <div className="bg-white">
        <Testimonial />
      </div>
      <Section>
        <Verifications />
      </Section>
      <Section>
        <ServiceTranscripts />
      </Section>
      <Section>
        <EasyToUse />
      </Section>
      <Footer />
    </div>
  );
};

export default IndexPage;
