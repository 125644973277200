import React from "react";
import Renderer from "./Renderer";
import { graphql } from "gatsby";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import Container from "../components/Container";
import Breadcrumbs from "../components/Breadcrumbs";

const NewsPostTemplate = ({ data, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const pages = [
    { name: "News", to: "/news" },
    { name: post.frontmatter.title },
  ];

  const excerpt = post.frontmatter.excerpt ?? post.excerpt;
  return (
    <div>
      <Nav />
      <Seo title={siteTitle} description={excerpt} />
      <Container>
        <div className="py-4 border-b">
          <Breadcrumbs pages={pages} />
        </div>
        <article
          className="mx-auto my-6 max-w-2xl p-4"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className="mb-4">
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </header>

          <section className="prose">
            <Renderer>{post.body}</Renderer>
          </section>
        </article>
      </Container>
      <Footer />
    </div>
  );
};

export default NewsPostTemplate;

export const pageQuery = graphql`
  query NewsPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        excerpt
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
