import "./src/styles/global.css";

export const onRouteUpdate = () => {
  if (
    process.env.NODE_ENV === `production` &&
    window.location.hostname !== "preview.7works.org" &&
    typeof window.plausible !== "undefined"
  ) {
    window.plausible(`pageview`);
  }
};
