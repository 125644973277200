import React from "react";
import { useField } from "react-final-form";

type TextAreaProps = Pick<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  "aria-describedby" | "autoComplete" | "rows" | "id" | "name"
>;

const className =
  "disabled:bg-gray-200 transition-colors bg-gray-50 disabled:cursor-not-allowed py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-gray-300 rounded-md";

type Props = {
  name: string;
} & TextAreaProps;

const TextField = (props: Props) => {
  const { rows, id, name, autoComplete } = props;
  const ariaDescribedBy = props["aria-describedby"];
  const { input, meta } = useField(name);
  const { submitting } = meta;
  const disabled = submitting;

  return (
    <textarea
      type="text"
      disabled={disabled}
      id={id}
      rows={rows}
      autoComplete={autoComplete}
      aria-describedby={ariaDescribedBy}
      className={className}
      {...input}
    />
  );
};

export default TextField;
