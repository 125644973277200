import React, { useState } from "react";
import { Link } from "gatsby";

import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Header from "../components/Header";

const rows = [1, 10, 50, 100, 1000, 10000];
const PricingTable = () => {
  return (
    <table className="mx-auto">
      <thead>
        <tr className="font-bold border-b border-black">
          <td align="right">Students</td>
          <td className="w-4"></td>
          <td className="ml-4 p-1">Price</td>
        </tr>
      </thead>
      <tbody>
        {rows.map((r) => (
          <tr className="py-1">
            <td align="right">
              {r.toLocaleString()} student{r === 1 ? "" : "s"}
            </td>
            <td className="w-4"></td>
            <td className="p-1">${r.toLocaleString()} / year</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

type FeatureBlockProps = {
  title: string;
  items: string[];
};

const FeatureBlock = ({ title, items }: FeatureBlockProps) => {
  return (
    <div className="flex flex-col justify-start space-y-2">
      <div className="uppercase font-bold text-blue-600">{title}</div>
      <ul className="list-disc list-inside">
        {items.map((i) => (
          <li key={i}>{i}</li>
        ))}
      </ul>
    </div>
  );
};

const Pricing = () => (
  <div className="bg-gray-50">
    <SEO title="Pricing" />
    <Nav showCta={false} />
    <Container>
      <div className="flex flex-col items-center my-8">
        <Header
          className="hidden sm:block"
          text="Simple, Transparent Pricing"
        />
        <Header className="sm:hidden" text="Simple Pricing" />
        <div className="text-gray-700 text-lg mt-2 italic text-center">
          <span className="text-blue-700 font-bold">$1 per student</span>{" "}
          annually
          <span className="hidden sm:inline">
            . Simple pricing that works for schools large or small
          </span>
        </div>
      </div>
      <div className="flex bg-white flex-col lg:flex-row mb-16 shadow-lg border border-gray-300">
        <div className="flex flex-col flex-1 p-8">
          <h1 className="mb-2 text-gray-800">Completely Risk Free</h1>
          <p>
            7works is <b>completely free</b> for the first 90 days. With no
            contracts to sign, no setup fees, and no credit card required - try
            out 7works without waiting on any approvals from your school
            administration.
          </p>
          <div className="mx-8 my-8 border border-gray-200" />
          <div className="sm:mx-8 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <FeatureBlock
              title="No Hassle"
              items={[
                "Instant access via QR code",
                "Login with Gmail",
                "No setup fee",
                "Free data migration",
              ]}
            />
            <FeatureBlock
              title="Unlimited..."
              items={[
                "Service hours uploaded",
                "Admin accounts",
                "SMS & email verifications",
                "Reports & data exports",
              ]}
            />
            <FeatureBlock
              title="And Much More"
              items={[
                "Full historical data retention",
                "Automatic progress emails",
                "Access to all future features",
              ]}
            />
          </div>
        </div>
        <div className="flex flex-col items-center p-8 bg-slate-100 border-t lg:border-t-0 lg:border-l border-slate-300">
          <PricingTable />
          <div className="text-center italic text-gray-500">
            {" "}
            or any size in between
          </div>
          <Link
            to="/signup"
            className="mt-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-100 bg-gray-700 hover:bg-gray-800"
          >
            Get Access
          </Link>
        </div>
      </div>
    </Container>
    <Footer />
  </div>
);

export default Pricing;
