import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";

import photosGif from "./photos.gif";

const Success = ({ header, children }) => {
  return (
    <div className="bg-green-50 pl-6 p-2 border-l-4 border-green-600 text-green-900">
      {header && <div className="font-bold">{header}</div>}
      {children}
    </div>
  );
};

const Info = ({ header, children }) => {
  return (
    <div className="bg-blue-50 pl-6 p-2 border-l-4 border-blue-600 text-blue-900">
      {header && <div className="font-bold">{header}</div>}
      {children}
    </div>
  );
};

const Warning = ({ header, children }) => {
  return (
    <div className="bg-orange-200 pl-6 p-2 border-l-4 border-orange-900 text-orange-900">
      {header && <div className="font-bold">{header}</div>}
      {children}
    </div>
  );
};

const Danger = ({ children }) => {
  return <div className="bg-red-600">{children}</div>;
};

const Image = ({ caption, children }) => {
  return (
    <div className="my-8">
      <div className="not-prose border border-gray-300 p-0">{children}</div>
      <figcaption className="text-center text-gray-600 italic">
        {caption}
      </figcaption>
    </div>
  );
};

const PhotosImage = ({ caption }) => {
  return (
    <div className="flex flex-col items-center my-8">
      <div className="not-prose p-0 mx-auto border">
        <img src={photosGif} />
      </div>
      <figcaption className="text-center text-gray-600 italic">
        {caption}
      </figcaption>
    </div>
  );
};

const shortcodes = { Link, Success, Info, Warning, Danger, Image, PhotosImage };

interface Props {
  children: any;
}

const Renderer = ({ children }: Props) => {
  return (
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  );
};

export default Renderer;
