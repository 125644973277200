import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useLocation } from "@reach/router";
import classNames from "./classNames";

const query = graphql`
  query DocsSidebar {
    docs: allMdx(
      filter: { fields: { slug: { regex: "//docs/.*$/" } } }
      sort: { fields: [fileAbsolutePath], order: ASC }
      limit: 1000
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`;

const SectionHeader = ({ name, to }) => {
  const loc = useLocation();
  const active = loc.pathname === to;
  const className = classNames(
    "font-serif font-semibold text-lg text-gray-700 mt-6 first:mt-0",
    active ? "text-blue-500" : "text-gray-700"
  );
  return (
    <div className={className}>
      <Link className="hover:underline" to={to}>
        {name}
      </Link>
    </div>
  );
};

const SectionLink = ({ name, to }) => {
  const loc = useLocation();
  const active = loc.pathname === to;
  const className = classNames(
    "ml-2 hover:underline mt-1 pl-2 px-2 text-gray-700 border-l-2",
    active
      ? "font-semibold text-blue-500 border-blue-500"
      : "border-slate-100 text-gray-700 hover:border-slate-200"
  );
  return (
    <Link to={to}>
      <div className={className}>{name}</div>
    </Link>
  );
};

interface Folder {
  name: string;
  to: string;
  items: Folder[];
}

const DocsSidebar = () => {
  const data = useStaticQuery(query);
  const sections: Folder[] = [];
  let items: Folder[] = [];
  for (const d of data.docs.nodes) {
    const { slug } = d.fields;
    const suffix = slug.replace(/^\/docs\//, "");
    const parts = suffix.split("/");
    if (parts.length === 1) {
      items = [];
      sections.push({
        name: d.frontmatter.title,
        to: slug,
        items,
      });
    } else if (parts.length === 2) {
      items.push({
        name: d.frontmatter.title,
        to: slug,
        items: [],
      });
    }
  }

  return (
    <div className="hidden md:block pb-8 text-sm px-4 md:mr-4 border-r">
      {sections.map((s, idx) => (
        <>
          <SectionHeader key={idx} name={s.name} to={s.to} />
          <ul>
            {s.items.map((item) => (
              <SectionLink key={item.to} name={item.name} to={item.to} />
            ))}
          </ul>
        </>
      ))}
    </div>
  );
};

export default DocsSidebar;
