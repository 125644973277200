import React from "react";
import { graphql, Link } from "gatsby";
import Renderer from "./Renderer";
import Nav from "../components/Nav";
import Seo from "../components/Seo";
import Container from "../components/Container";
import DocsSidebar from "../components/DocsSidebar";
import Breadcrumbs from "../components/Breadcrumbs";

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";

const PrevButton = ({ node }) => {
  if (!node) {
    return <div className="flex-1" />;
  }

  return (
    <Link
      to={node.fields.slug}
      className="hover:border-blue-500 group items-start flex flex-col flex-1 border px-4 py-2 rounded no-underline"
    >
      <div className="text-gray-300 group-hover:text-gray-500 flex flex-row items-center">
        <ChevronDoubleLeftIcon className="h-4 w-4 inline-block items-center mr-2" />
        Previous
      </div>
      <div className="font-medium text-gray-400 group-hover:text-blue-700">
        {node.frontmatter.title}
      </div>
    </Link>
  );
};

const NextButton = ({ node }) => {
  if (!node) {
    return <div className="flex-1" />;
  }

  return (
    <Link
      to={node.fields.slug}
      className="hover:border-blue-500 group items-end flex flex-col flex-1 border px-4 py-2 rounded no-underline"
    >
      <div className="text-gray-300 group-hover:text-gray-500 flex flex-row items-center">
        Next
        <ChevronDoubleRightIcon className="h-4 w-4 inline-block items-center ml-2" />
      </div>
      <div className="font-medium text-gray-400 group-hover:text-blue-700">
        {node.frontmatter.title}
      </div>
    </Link>
  );
};

const PrevNext = ({ prev, next }) => {
  return (
    <div className="grid grid-cols-2 gap-4 max-w-[65ch] my-8">
      <div className="flex-1">
        <PrevButton node={prev} />
      </div>
      <div className="flex-1">
        <NextButton node={next} />
      </div>
    </div>
  );
};

const DocLeafTemplate = ({ data, location }) => {
  const { mdx: post, prev, next } = data;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const title = post?.frontmatter?.title;
  const description = post?.frontmatter?.description;

  const pages: React.ComponentProps<typeof Breadcrumbs>["pages"] = [
    { name: "Docs", to: "/docs" },
  ];

  if (location.pathname.startsWith("/docs/concepts")) {
    pages.push({ name: "The Basics", to: "/docs/concepts" });
  }

  if (location.pathname.startsWith("/docs/admins")) {
    pages.push({ name: "Admin Guide", to: "/docs/admin" });
  }

  if (location.pathname.startsWith("/docs/students")) {
    pages.push({ name: "Student Guide", to: "/docs/students" });
  }

  if (location.pathname.split("/").length > 3) {
    pages.push({ name: title });
  }

  return (
    <div>
      <Nav />
      <Seo
        title={title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Container>
        <div className="py-4 border-b">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="flex items-start flex-row my-6">
          <DocsSidebar />
          <article
            className="flex-1 md:px-4"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header className="mb-4 border-b pb-4">
              <h1>{title}</h1>
              <div className="italic mt-2">{description}</div>
            </header>
            <section className="prose pt-2">
              <Renderer>{post.body}</Renderer>
            </section>
            <PrevNext prev={prev} next={next} />
          </article>
        </div>
      </Container>
    </div>
  );
};

export default DocLeafTemplate;

export const pageQuery = graphql`
  query DocLead($id: String!, $prevId: String, $nextId: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    prev: mdx(id: { eq: $prevId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
