import React from "react";
import { graphql, Link } from "gatsby";
import Renderer from "./Renderer";
import Nav from "../components/Nav";
import Seo from "../components/Seo";
import Container from "../components/Container";
import DocsSidebar from "../components/DocsSidebar";
import Breadcrumbs from "../components/Breadcrumbs";

const DocIndexTemplate = (props) => {
  const { data, location } = props;
  const {
    children: { nodes: children },
    post,
  } = data;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const title = post?.frontmatter?.title;
  const description = post?.frontmatter?.description;

  const pages: React.ComponentProps<typeof Breadcrumbs>["pages"] = [
    { name: "Docs", to: "/docs" },
  ];

  if (location.pathname.startsWith("/docs/concepts")) {
    pages.push({ name: "The Basics", to: "/docs/concepts" });
  }

  if (location.pathname.startsWith("/docs/admins")) {
    pages.push({ name: "Admin Guide", to: "/docs/admins" });
  }

  if (location.pathname.startsWith("/docs/students")) {
    pages.push({ name: "Student Guide", to: "/docs/students" });
  }

  if (location.pathname.split("/").length > 3) {
    pages.push({ name: title });
  }

  return (
    <div>
      <Nav />
      <Seo
        title={title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Container>
        <div className="py-4 border-b">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="flex flex-row items-start my-6">
          <DocsSidebar />
          <article
            className="flex-1 md:px-4"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header className="mb-4 border-b pb-4">
              <h1>{title}</h1>
              <div className="italic mt-2">{description}</div>
            </header>
            <section className="prose pt-2">
              <Renderer>{post.body}</Renderer>
              <h4>Table of Contents</h4>
              <ul>
                {children.map((c) => (
                  <li>
                    <Link to={c.fields.slug}>{c.frontmatter.title}</Link>
                  </li>
                ))}
              </ul>
            </section>
          </article>
        </div>
      </Container>
    </div>
  );
};

export default DocIndexTemplate;

export const pageQuery = graphql`
  query DocIndex($id: String!, $childrenRe: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
    children: allMdx(
      filter: { fields: { slug: { regex: $childrenRe } }, id: { ne: $id } }
      sort: { fields: [fileAbsolutePath], order: ASC }
      limit: 1000
    ) {
      nodes {
        id
        fields {
          slug
          origSlug
        }
        frontmatter {
          title
        }
      }
    }
  }
`;
