declare global {
  interface Window {
    plausible: (
      name: string,
      options?: { callback?: () => void; props?: { [key: string]: string } }
    ) => void;
  }
}

const plausible = globalThis.plausible;
export default plausible;
