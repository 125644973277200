import React from "react";

interface Props {
  text: React.ReactNode;
  className?: string;
}

const Header = ({ text, className = "" }: Props) => {
  return (
    <div className={`${className} text-center text-4xl font-bold font-serif`}>
      {text}
    </div>
  );
};

export default Header;
