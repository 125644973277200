import React, { useRef, useEffect } from "react";
import { fetch } from "whatwg-fetch";
import {
  PhoneIcon,
  ChatAltIcon,
  HomeIcon,
  MailIcon,
} from "@heroicons/react/solid";
import { FORM_ERROR } from "final-form";
import { Form, useForm, useFormState } from "react-final-form";

import Seo from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from "../components/Container";
import TextField from "../components/form/TextField";
import TextareaField from "../components/form/TextareaField";
import Submit from "../components/form/Submit";
import SubmitError from "../components/form/SubmitError";

const HiddenPhone = ({ title, className }) => {
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    const displayPhone = window.atob("KzEgKDYwMikgODMzLTEzMzg=");
    const phone = window.atob("KzE2MDI4MzMxMzM4");
    if (ref.current) {
      ref.current.href = `tel:${phone}`;
      ref.current.innerHTML = displayPhone;
    }
  }, [ref]);

  return (
    <a ref={ref} title={title} className={className}>
      loading...
    </a>
  );
};

const HiddenEmail = ({ title, className }) => {
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    const email = window.atob("c3VwcG9ydEA3d29ya3Mub3Jn");
    if (ref.current) {
      ref.current.href = `mailto:${email}`;
      ref.current.innerHTML = email;
    }
  }, [ref]);

  return (
    <a ref={ref} title={title} className={className}>
      loading...
    </a>
  );
};

async function sendToApi(method: string, path: string, data: any) {
  const body = data ? JSON.stringify(data) : undefined;
  let res;
  let baseUrl = "https://app.7works.org";
  if (window?.location?.host === "localhost:8000") {
    baseUrl = "";
  }

  try {
    res = await fetch(`${baseUrl}${path}`, {
      method,
      credentials: "same-origin",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } catch (err: any) {
    return { body: null, error: err?.message, status: 0 };
  }

  if (!res.ok) {
    const respText = await res.text();
    let error: string | null = null;
    let jResp;
    try {
      jResp = JSON.parse(respText);
    } catch (err) {
      // do nothing
    }

    if (jResp && jResp.message) {
      error = jResp.message;
    } else if (respText) {
      error = respText;
    } else {
      error = `Error (${res.status}): ${method} to ${path}`;
    }

    return { body: null, error, status: res.status };
  }

  try {
    const respBody = await res.json();
    return { body: respBody, error: null, status: res.status };
  } catch (err: any) {
    return { body: null, error: err.message, status: res.status };
  }
}

const FormFooter = () => {
  const s = useFormState({
    subscription: { submitError: true, submitSucceeded: true },
  });

  return (
    <>
      {s.submitSucceeded && (
        <div className="col-span-2">
          <ThankYou />
        </div>
      )}
      <div className="sm:col-span-2 sm:flex sm:justify-end">
        <SubmitError />
      </div>
      {!s.submitSucceeded && (
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <Submit>Submit</Submit>
        </div>
      )}
    </>
  );
};

/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from "@heroicons/react/solid";

const ThankYou = () => {
  const form = useForm();
  const onSendAnother = () => {
    form.restart();
  };

  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Message received
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <p>Thank you for the message, we'll get back to you ASAP!</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                onClick={onSendAnother}
                type="button"
                className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              >
                Send Another Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactForm = () => {
  const onSubmit = async (values: Record<string, any>) => {
    const { error } = await sendToApiSlow("POST", "/api/webform", {
      name: "contact",
      data: values,
    });

    if (error) {
      return { [FORM_ERROR]: error };
    }

    return null;
  };

  return (
    <div className="bg-white py-10 px-0 sm:px-10 lg:col-span-3 xl:p-12">
      <h3 className="text-lg font-medium text-gray-900">Send a message</h3>
      <Form onSubmit={onSubmit}>
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <div className="mt-1">
                <TextField name="name" id="name" autoComplete="given-name" />
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email
                </label>
              </div>
              <div className="mt-1">
                <TextField id="email" name="email" autoComplete="email" />
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-900"
                >
                  Phone
                </label>
                <span id="phone-optional" className="text-sm text-gray-500">
                  Optional
                </span>
              </div>
              <div className="mt-1">
                <TextField
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  aria-describedby="phone-optional"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-900"
                >
                  Message
                </label>
              </div>
              <div className="mt-1">
                <TextareaField
                  id="message"
                  name="message"
                  rows={4}
                  aria-describedby="message-max"
                />
              </div>
            </div>
            <FormFooter />
          </form>
        )}
      </Form>
    </div>
  );
};

const ContactPane = () => {
  return (
    <div className="relative bg-white my-8">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl font-serif">
              Contact Info
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Whether you're curious about features, looking for a demo, or even
              press — we're ready to answer any and all questions.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div className="mt-6 text-lg">
                <dt className="sr-only">Home Location</dt>
                <dd className="flex items-center">
                  <HomeIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Phoenix, AZ, USA</span>
                </dd>
              </div>
              <div className="mt-3 text-lg">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex items-center group">
                  <PhoneIcon
                    className="transition duration-1000 flex-shrink-0 group-hover:-translate-x-full h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <ChatAltIcon
                    className="transition duration-1000 absolute -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <HiddenPhone
                    title="Call or text"
                    className="relative flex-row ml-3 hover:underline"
                  />
                </dd>
              </div>
              <div className="mt-3 text-lg">
                <dt className="sr-only">Email</dt>
                <dd className="flex items-center">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <HiddenEmail
                    title="Send an email"
                    className="relative flex-row ml-3 hover:underline"
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

async function sendToApiSlow(method: string, path: string, data: any) {
  const p = new Promise((resolve) => setTimeout(resolve, 1000));
  return sendToApi(method, path, data).then(
    async (res) => {
      await p;
      return Promise.resolve(res);
    },
    async (err) => {
      await p;
      return Promise.reject(err);
    }
  );
}

const Contact = () => {
  return (
    <div className="bg-white min-h-full">
      <Seo title="Contact" />
      <Nav />
      <Container>
        <ContactPane />
      </Container>
      <Footer />
    </div>
  );
};

export default Contact;
