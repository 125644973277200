import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Header from "../components/Header";

const HiddenEmail = () => {
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    const email = window.atob("c3RldmVuQDd3b3Jrcy5vcmc=");
    if (ref.current) {
      ref.current.href = `mailto:${email}`;
      ref.current.innerHTML = email;
    }
  }, [ref]);

  return <a ref={ref}>loading...</a>;
};

const About = () => {
  return (
    <div>
      <SEO title="About Us" />
      <Nav />
      <Container>
        <div className="flex flex-col items-center py-8 border-b">
          <Header text={"Hi! I'm Steven"} />
          <div className="text-gray-700 text-lg mt-2 italic text-center">
            7works is a tiny company. That keeps it affordable (for you) and fun
            (for me)
          </div>
        </div>
        <div className="my-16 flex flex-col justify-center items-center md:items-start md:flex-row">
          <div className="flex flex-col mb-8">
            <StaticImage
              className="rounded-lg shadow-2xl"
              height={300}
              width={300}
              layout="fixed"
              src="../images/steven.jpg"
              alt="Very handsome man"
            />
          </div>
          <div className="md:ml-16">
            <div className="prose ml-2 mt-0">
              <p>
                <span className="-ml-2 text-xl font-bold">
                  Long story short...
                </span>
                <br />I created 7works because my mom (Hi Mom!!) was the Campus
                Ministry Director at{" "}
                <a href="https://stanthony.com/high-school">St.&nbsp;Anthony</a>
                , and I wanted to help her out. My experience at Dropbox taught
                me to build easy-to-use and reliable software. So, I did - and
                she loved it, the students loved it.
              </p>
              <p>
                <span className="-ml-2 text-lg">Once upon a time...</span>
                <br />I was enamored with software from the moment I discovered
                it. I was self-taught at first - going to the library to check
                out programming books (no internet at home just yet). I started
                to dabble in web development in high school, and made a few
                websites.
              </p>
              <p>
                Those sites are <i>long</i> gone by now, but they did push me
                towards a software education. I went on to study Computer
                Science at the{" "}
                <a href="https://illinois.edu/">University of Illinois</a> and
                graduated with high honors in 2011. From there - I worked for
                startups and tech giants alike - from Palm (who remembers the
                Palm Pilot!?), to Mailbox (10-person startup, with millions of
                users), to Dropbox (who I owe much of my experience to).
              </p>
              <p>
                <span className="-ml-2 text-lg">7works!</span>
                <br />
                After about 10 years in the industry, I began to notice a
                distinct lack of high quality, affordable, <i>niche</i>{" "}
                software. I had already been working with my mom on data
                tracking and approvals for St. Anthony's service program, and
                decided to put in the effort to make it work for many schools,
                not just St. Anthony. <b>7works was born!</b>
              </p>
              <p>
                <span className="-ml-2 text-lg">Moving Forward</span>
                <br />
                Good software is never done, it changes and evolves. I'm looking
                forward to hearing your feedback and ideas. Send me an email at{" "}
                <HiddenEmail /> with any and all of your ideas.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
